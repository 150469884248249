html *:not(svg, path) {
    color: #3e526b;
}

body {
    background-color: #eee;
}

.h-0 {
    height: 0px;
}

.cursor-pointer {
    cursor: pointer;
}

.self-center {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.btn svg > path {
    color: #fff;
}

svg.disabled > path {
    color: #c0c0c0;
}

.text-prewrap {
    white-space: pre-wrap;
}

.exp {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background-color: #0d6efd;
    color: #fff !important;
    width: 16px;
    height: 16px;
    border-radius: 8px;
    text-align: center;
    font-size: 0.7rem;
}

.btn-primary {
    --bs-btn-color: #fff;
    --bs-btn-bg: #3fa535;
    --bs-btn-border-color: #3fa535;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #348a2c;
    --bs-btn-hover-border-color: #348a2c;
    --bs-btn-focus-shadow-rgb: 63, 155, 53;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #348a2c;
    --bs-btn-active-border-color: #348a2c;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #3fa535;
    --bs-btn-disabled-border-color: #3fa535;
}

.btn-group .btn-primary.active {
    --bs-btn-color: #fff;;
    --bs-btn-bg: #3fa535;

}

.btn-group .btn-primary {
    --bs-btn-color: #3fa535;
    --bs-btn-bg: transparent;
}

.icon-main-background {
    background-color: #3fa535;
}

.main-container {
    margin: 0 auto;
    padding: 0 12px;
    display: flex;
    max-width: 992px;
    width: 100%;
    height: 100%;
}

.page-call .main-container {
    background-color: #fff;
}

.page-private .main-container {
    background-color: #fff;
}

.page-public .main-container {
    background-color: #fff;
}

.page-center {
    align-self: center;
    align-items: center;
    justify-content: center;
    width: 100% !important;
    height: 100% !important;
}

.page-center-spinner {
    align-self: center;
    align-items: center;
    justify-content: center;
    width: 100% !important;
    height: 100vh !important;
}

.sticky-pagination-bar,
.sticky-button-bar {
    margin-top: 1rem;
    padding: .8rem;
    background-color: rgba(255,255,255,.9);
    padding-bottom: calc(0.8rem + 40px);
}

.menu-item > a {
    color: #0d6efd;
    text-decoration: none;
}

.menu-item > a .dropdown-label {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 80%;
    display: inline-block;
    vertical-align: middle;
}

.span-link,
.span-link > * {
    color: #0d6efd;
    text-decoration: none;
    cursor: pointer;
}

.span-link-hover:hover {
    text-decoration: underline;
}

.span-link-nav {
    text-decoration: none;
    cursor: pointer;
}

.medium-icon {
    font-size: 2rem;
}

.big-icon {
    font-size: 3rem;
}

.shared-place {
    background-color: #f5f5dc;
}

.page-title {
    border-bottom: 1px solid #f0f0f0; /* #3e526b66;*/
}

footer .navbar-brand {
    font-size: 0.8rem;
}

.empty-list {
    position: relative;
}

.empty-list li {
    border-style: dashed;
}

/*
.empty-list-badge {
    overflow: hidden;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 200px;
    height: 200px;
    text-align: center;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border-radius: 100px;
    border: 1px dashed rgba(0, 0, 0, .13);
}

.empty-list-badge .image {
    margin: 0 auto;
    width: 180px;
    height: 115px;
    background-image: url("https://devgate.linkello.com/assets/images/empty-list.jpg");
    background-size: contain;
    background-repeat: no-repeat;
}
*/

.empty-list-message {
    position: absolute;
    width: 75%;
    top: 50%;
    left: 50%;
    //color: #c0c0c0;
    text-align: center;
    transform: translate(-50%, -50%);
}

.shortcut {

    max-width: 200px;

    a,
    .span-link {
        text-decoration: none;
        color: #3e526b !important;
    }
}

.container-header {
    background-color: #f1f1f1;
    padding: 14px;
    border-radius: 10px;
}

.item-with-icon {
    .icon-container {
        width: 40px;
        min-width: 40px;
    }
}

.qr-code {
    aspect-ratio: 1/1;
    margin: 0 auto;
    border: 6px solid #c0c0c0;
    border-radius: 24px;
    width: 80%;
    max-width: 150px;

    canvas {
        width: 100%;
        border-radius: 24px;
        vertical-align: bottom;
    }
}

.relay-picture {
    aspect-ratio: 1/1;
    overflow: hidden;
    position: relative;
    display: inline-block;
    width: 80%;
    max-width: 150px;
    border-radius: 50%;
    background-color: #fff;
    border: 6px solid #c0c0c0;

    img {
        margin-top: 25px;
        width: 70%;
        height: 70%;
        object-fit: cover;
    }
}

.user-picture {
    aspect-ratio: 1/1;
    overflow: hidden;
    position: relative;
    display: inline-block;
    width: 80%;
    max-width: 150px;
    border-radius: 50%;
    background-color: #fff;
    border: 6px solid #c0c0c0;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.btn {
    padding: 6px 16px;
    border-radius: 20px;
}

.btn.btn-sm {
    padding: 4px 12px;
    border-radius: 16px;
}

.modal-header {
    border-bottom: 0;
}

.modal-body {
    padding-top: 0;
    padding-bottom: 0;
}

.modal-footer {
    border-top: 0;
}

.icon-wrapper-button {
    position: relative;
    display: flex;
    width: 32px;
    height: 32px;
    align-items: center;
    justify-content: center;
    border-radius: 16px;
    border: solid 1px;
    cursor: pointer;
}

.icon-wrapper-circle {
    position: relative;
    display: flex;
    width: 32px;
    height: 32px;
    align-items: center;
    justify-content: center;
    border-radius: 16px;
}

.icon-wrapper-circle svg path {
    color: #fff
}

.badge-admin {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    right: -5px;
    top: -2px;
    width: 16px;
    height: 16px;
    font-size: 10px;
    background-color: red;
    border-radius: 8px;
}

.outlined-container {
    padding: 7px 14px;
    border: 1px solid rgba(0,0,0,0.13);
    border-radius: 0.375rem;
}

.link-underline {
    padding: 0 2px;
    color: #3e526b;
    cursor: pointer;
    border-radius: 0.3rem;
    text-decoration: underline;
}

.link {
    padding: 0 2px;
    color: #3e526b;
    border: 1px solid #3e526b83;
    cursor: pointer;
    border-radius: 0.3rem;
    text-decoration: none;
}

.float-clearfix::after {
    content: " ";
    display: block;
    height: 0;
    clear: both;
}

.warning-message {
    padding: 10px;
    background-color: #fff3cd;
    border-radius: 0.373rem;
}

.product-picture {
    aspect-ratio: 1/1;
    overflow: hidden;
    position: relative;
    display: inline-block;
    width: 100%;
    max-width: 200px;
    border-radius: 5px;
    background-color: #fff;
    border: 5px solid #c0c0c0;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.catalog-picture {
    aspect-ratio: 1/1;
    overflow: hidden;
    position: relative;
    display: inline-block;
    width: 100%;
    border-radius: 5px;
    background-color: #fff;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.cart-picture {
    aspect-ratio: 1/1;
    overflow: hidden;
    position: relative;
    display: inline-block;
    width: 100%;
    max-width: 100px;
    border-radius: 5px;
    background-color: #fff;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.icon-wrapper-circle {
    position: relative;
    display: flex;
    width: 32px;
    height: 32px;
    align-items: center;
    justify-content: center;
    border-radius: 16px;
}

.icon-wrapper-circle svg path {
    color: #fff
}

.fs-small {
    font-size: 0.8rem;
}